<template>
  <v-sheet v-once class="bg-surface-light py-12">
    <v-container>
      <v-row>
        <v-col v-if="value.video" class="my-lg-n16" cols="12" lg="5">
          <v-card class="fill-height" rounded="0" variant="flat">
            <VideoCover
              :aspect-ratio="videoAspectRatio"
              :gradient="HOME_DIFFERENTIATORS_VIDEO_GRADIENT"
              :value="value.video"
              content-class="d-flex align-end justify-center"
              height="100%"
            >
              <PlayVideo
                v-if="!internalPlayer"
                :title="value.video.title"
                :video="value.video"
              />
              <PlayVideo
                v-else-if="!video"
                :title="value.video.title"
                :video="value.video"
                @click="() => (video = value.video)"
              />
              <VideoPlayer v-else :value="video" />
            </VideoCover>
          </v-card>
        </v-col>

        <v-col align-self="center" class="pl-lg-16">
          <v-row>
            <v-col>
              <TitleParagraphBlock
                em-class-title="text-decoration-underline"
                :value="value"
              />
            </v-col>

            <v-col class="flex-grow-0 d-none d-sm-flex align-start">
              <HdLogo :opacity="0.8" />
            </v-col>
          </v-row>

          <v-row v-if="value.items && value.items.length">
            <v-col
              v-for="item in value.items"
              :key="item.id"
              class="text-center"
              cols="12"
              sm=""
            >
              <v-icon
                v-if="typeof item.icon === 'string'"
                :icon="`hdl:${item.icon}`"
                class="mb-3"
                color="light-blue-darken-2"
                size="50"
              />

              <ContentBlock
                :value="item.content"
                class="text-body-1 text-left"
                em-class="font-weight-black"
                p-class="text-truncate-8"
              />
            </v-col>
          </v-row>

          <v-row v-if="value.cta && value.cta.action && value.cta.href">
            <v-col align="center">
              <v-btn
                :append-icon="mdiArrowRight"
                :href="value.cta.href"
                class="text-none"
                exact
                rounded="pill"
                size="large"
                variant="outlined"
                >{{ value.cta.action }}</v-btn
              >
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-sheet>
</template>

<script setup>
import { ref } from 'vue'
import { HOME_DIFFERENTIATORS_VIDEO_GRADIENT } from '#root/lib/consts'
import { mdiArrowRight } from '@mdi/js'

defineProps({
  internalPlayer: {
    default: false,
    type: Boolean
  },
  value: {
    required: true,
    type: Object
  }
})

const videoAspectRatio = 16 / 9
const video = ref(null)
</script>
